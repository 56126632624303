import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'requiredInput']

  connect() {
    this.requiredInputTargets.forEach(input => {
      input.dataset.action = "change->required-input#checkInputs paste->required-input#checkInputs keyup->required-input#checkInputs"
    })
    this.checkInputs()
  }

  checkInputs() {
    if (this.allInputsFilled()) {
      this.enableButtons()
    } else {
      this.disableButtons()
    }
  }
  
  // private

  allInputsFilled() {
    return this.requiredInputTargets.every(input => {
      return input.value.length > 0
    })
  }

  disableButtons() {
    this.buttonTargets.forEach(button => {
      button.disabled = true
    })
  }

  enableButtons() {
    this.buttonTargets.forEach(button => {
      button.disabled = false
    })
  }
}
