// Entry point for the build script in your package.json

import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import LocalTime from "local-time"
LocalTime.start()

// Setup stimulus and import controllers
import { Application } from "@hotwired/stimulus"
import { definitions } from 'stimulus:./controllers';

const application = Application.start()
application.load(definitions);

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import "trix"
import "@rails/actiontext"
