import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

const { connect, Participant } = require('twilio-video');

let room;
let controller;

// Responsible for managing a Twilio backed video chat
export default class extends Controller {
  static targets = ['leaveForm', 'media', 'participants']
  static values = {
    connected: Boolean,
    token: String,
    roomName: String,
    videoEnabled: String
  }

  connect() {
    this.join()
    controller = this
  }

  disconnect() {
    this.leave()
  }

  async join() {
    if(!this.connectedValue) {
      room = await connect(this.tokenValue, {
        room: this.roomNameValue,
        audio: true,
        video: this.isVideoEnabled
      })

      room.localParticipant.tracks.forEach(publication => {
        this.mediaTarget.appendChild(publication.track.attach())
      })

      this.handleParticipants(room)
      this.handleDisconnect(room)
      this.connectedValue = true
    }
  }

  handleLeave(event) {
    event.preventDefault()
    const confirmation = confirm("Are you sure you want to leave?")

    if (confirmation == true) {
      this.leave()
    } else {
      return
    }
  }

  leave() {
    room.localParticipant.tracks.delete()
    this.removeFromParticipantsList(room.localParticipant)
    // remove self from participant list
    room.disconnect()
    this.connectedValue = false

    if (this.hasLeaveFormTarget) {
      Rails.fire(this.leaveFormTarget, 'submit');
    }
  }

  mute() {
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable()
    })
  }

  unmute() {
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.enable()
    })
  }

  // Private

  handleParticipants(room) {
    // Add self to participants list
    this.addToParticipantsList(room.localParticipant)

    room.participants.forEach(participant => this.participantConnected(participant))
    room.on('participantConnected', this.participantConnected.bind(this))
    room.on('participantDisconnected', this.participantDisconnected.bind(this))
    room.on('trackSubscribed', this.trackSubscribed.bind(this))
    room.on('trackUnsubscribed', this.trackUnsubscribed.bind(this))
    room.on('trackDisabled', this.trackDisabled.bind(this))
    room.on('trackEnabled', this.trackEnabled.bind(this))
  }

  trackEnabled(track, participant) {
    const participantNameDiv = this.participantsTarget.querySelector(`#${participant.sid}`)
    const muteIcon = participantNameDiv.querySelector('.svg')

    participantNameDiv.removeChild(muteIcon)
  }

  trackDisabled(track, participant) {
    const participantDiv = document.getElementById(participant.sid)
    const template = document.getElementsByTagName("template")[0]
    const mutedSvg = template.content.cloneNode(true)
    participantDiv.appendChild(mutedSvg)
  }

  trackSubscribed(track, participant) {
    this.mediaTarget.appendChild(track.attach())
  }

  trackUnsubscribed(track, participant) {
    track.detach().forEach(element => element.remove())
  }

  participantConnected(participant) {
    // Create an element with the participant name/email id=participant.sid
    // Attach it to participantsTarget
    this.addToParticipantsList(participant)
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        controller.trackSubscribed(tracksDiv, publication.track);
      }
    });
  }

  participantDisconnected(participant) {
    // Remove from video chat window ... (this happens via trackUnsubscribed)
    // Remove participant participant list
    this.removeFromParticipantsList(participant)

    controller.leave()
  }

  handleDisconnect(room) {
    room.on('disconnected', room => {
      room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach()
        attachedElements.forEach(element => element.remove())
      })
    })
  }

  addToParticipantsList(participant) {
    const div = document.createElement('div')
    div.id = participant.sid
    div.innerText = participant.identity

    this.participantsTarget.appendChild(div)
  }

  removeFromParticipantsList(participant) {
    const participantNameDiv = document.getElementById(participant.sid)
    if(participantNameDiv) {
      this.participantsTarget.removeChild(participantNameDiv)
    }
  }

  get isVideoEnabled() {
    if (this.hasVideoEnabledValue) {
      return this.videoEnabledValue === "true"
    }
    return true
  }
}
