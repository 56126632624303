import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "tab", "resetTab", "item" ]
  static values = {
    index: Number
  }

  connect() {
    if (!this.hasResetTabTarget) {
      // ⏰ Wait for category carousels to set themselves up
      setTimeout(() => {
        this.showItem()
      });
    }
  }

  filter(event) {
    event.preventDefault()
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  reset(event) {
    event.preventDefault()
    this.resetTabTarget.classList.add("active")
    this.showAllItems()
  }

  showItem() {
    if (this.hasResetTabTarget) {
      this.resetTabTarget.classList.remove("active")
    }
    this.tabTargets.forEach((tab, index) => {
      const item = this.itemTargets[index]
      tab.classList.toggle("active", index == this.index)
      item.classList.toggle("hidden", index != this.index)
    })
  }

  showAllItems() {
    this.tabTargets.forEach(tab => tab.classList.remove("active"))
    this.itemTargets.forEach(item => item.classList.remove("hidden"))
  }

  get index() {
    return parseInt(this.indexValue)
  }

  set index(value) {
    this.indexValue = value
    this.showItem()
  }
}
