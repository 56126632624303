import { Controller } from "@hotwired/stimulus";

const { createLocalTracks } = require('twilio-video');

export default class extends Controller {
  static targets = ['preview']

  connect() {
    this.previewVideo()
  }

  async previewVideo() {
    const tracks = await createLocalTracks()
    const localVideoTrack = tracks.find(track => track.kind === 'video')
    this.previewTarget.appendChild(localVideoTrack.attach())
  }
}
