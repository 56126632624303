import { Controller } from "@hotwired/stimulus"
import { bytesToSize } from "../helpers"
import Uploader from "../uploader"

const maxFiles = 10

export default class extends Controller {
  static targets = [ "dropzone", "form", "input", "files", "minFilesMessage", "maxFilesMessage"]
  static values = { count: Number }

  dragover(event) {
    event.preventDefault()
    this.hover(true)
  }

  dragleave(event) {
    event.preventDefault()
    this.hover(false)
  }

  drop(event) {
    event.preventDefault()
    if (this.exceedsMaxFiles()) return
    const files = event.dataTransfer.files
    Array.from(files).filter(file => this.isValid(file)).forEach(file => this.uploadFile(file))
    this.hover(false)
  }

  chooseFiles() {
    if (this.exceedsMaxFiles()) return
    this.inputTarget.click()
  }

  addFiles() {
    if (this.exceedsMaxFiles()) return
    Array.from(this.inputTarget.files).filter(file => this.isValid(file)).forEach(file => this.uploadFile(file))
    this.inputTarget.value = null
  }

  remove(event) {
    const id = event.currentTarget.dataset.uploadId
    document.getElementById(`direct-upload-${id}`).remove()
    document.getElementById(`direct-upload-input-${id}`).remove()
    this.countValue--
  }

  countValueChanged() {
    this.maxFilesMessageTarget.classList.toggle("hidden", !this.exceedsMaxFiles())
    this.minFilesMessageTarget.classList.add("hidden", this.countValue > 0)
  }

  submit(event) {
    event.preventDefault()
    if (this.countValue == 0) {
      this.minFilesMessageTarget.classList.toggle("hidden")
    } else {
      this.formTarget.submit()
    }
  }

  // private

  hover(force) {
    this.dropzoneTarget.classList.toggle("drop-zone__hover", force)
  }

  exceedsMaxFiles() {
    return this.countValue >= maxFiles
  }

  isValid(file) {
    if (this.isValidFile(file) && this.isValidSize(file)) return true
    this.renderError(file)
  }

  isValidFile(file) {
    const acceptedTypes = this.inputTarget.getAttribute("accept").split(",")
    const mimeType = file.type
    return acceptedTypes.some(type => type === mimeType)
  }

  isValidSize(file) {
    const maxSize = 500 * 1024 * 1024
    return file.size <= maxSize
  }

  renderError(file) {
    this.filesTarget.insertAdjacentHTML("beforeend", `
      <div class="direct-upload direct-upload__error">
        <div class="grid grid-cols-10">
          <div class="col-span-10 flex">
            <span class="text-ellipsis overflow-hidden whitespace-nowrap pr-2">${file.name}</span>
            <span class="text-gray-500 whitespace-nowrap">${bytesToSize(file.size)}</span>
          </div>
        </div>
        <div class="direct-upload--validation-error text-red-500">
          <span>Files must be csv, pdf, doc, docx, xls, or xlsx and less than 500 MB.</span>
        </div>
      </div>
    `)
  }

  toggleError(id, reason) {
    const upload = document.getElementById(`${id}`)
    upload.querySelector(".direct-upload--progress").classList.add("hidden")
    upload.querySelector(`.direct-upload--${reason}-error`).classList.remove("hidden")
    upload.classList.add("direct-upload__error")
  }

  uploadFile(file) {
    this.dispatch("disableForm")
    const url = this.inputTarget.dataset.directUploadUrl
    const uploader = new Uploader(file, url)

    uploader.start((error, blob) => {
      if (error) {
        this.toggleError(`direct-upload-${uploader.uploadId}`, "uploader")
      } else {
        const hiddenField = document.createElement("input")
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.setAttribute("id", `direct-upload-input-${uploader.uploadId}`)
        hiddenField.name = this.inputTarget.name
        this.formTarget.appendChild(hiddenField)
        this.countValue++
      }
      this.dispatch("enableForm")
    })

    this.filesTarget.insertAdjacentHTML("beforeend", `
      <div id="direct-upload-${uploader.uploadId}" class="direct-upload">
        <div class="grid grid-cols-10">
          <div class="col-span-9 flex">
            <span class="text-ellipsis overflow-hidden whitespace-nowrap pr-2">${file.name}</span>
            <span class="text-gray-500 whitespace-nowrap">${bytesToSize(file.size)}</span>
          </div>
          <div class="col-span-1 text-right">
            <a class="text-black" data-upload-id="${uploader.uploadId}" data-action="click->upload#remove" title="Remove file">x</a>
          </div>
        </div>
        <div class="direct-upload--progress shadow-inner rounded bg-gray-200">
          <div id="direct-upload-progress-${uploader.uploadId}" class="border border-power-blue-500 rounded w-0"></div>
        </div>
        <div class="direct-upload--uploader-error text-red-500 hidden">
          <span>Unable to upload this file. Please try again.</span>
        </div>
      </div>
    `)
  }
}
