import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    payload: String
  }

  show(event) {
    event.preventDefault()
    document.querySelector("#modal").innerHTML = this.payloadValue
  }
}
