import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { submit: String, with: String }

  connect() {
    if (!this.hasWithValue) this.withValue = "Processing ..."
    if (!this.hasSubmitValue) this.submitValue = "Submit"
  }

  disableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.value = this.withValue
    })
  }

  enableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = false
      button.value = this.submitValue
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit']")
  }
}
