import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'overlay' ]

  connect() {
    this.bodyElement.classList.add('modal-open')
  }

  close(event) {
    event.preventDefault()
    this.overlayTarget.parentNode.removeChild(this.overlayTarget)
    this.bodyElement.classList.remove('modal-open')
  }

  // private

  get bodyElement() {
    return document.querySelector('body');
  }
}
