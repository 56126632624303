import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "timer" ]
  static values = {
    startTime: String
  }

  connect() {
    this.startTime = this.initialStartTime

    setInterval(() => {
      this.updateTime()
    }, 500)
  }

  updateTime() {
    if (this.displayHours > 0) {
      this.timerTarget.innerHTML = `${this.displayHours}:${this.displayMinutes}:${this.displaySeconds}`
    } else {
      this.timerTarget.innerHTML = `${this.displayMinutes}:${this.displaySeconds}`
    }
  }

  zeroPad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  // private

  get initialStartTime() {
    if (this.startTimeValue) {
      const dateString = this.startTimeValue
      return  Date.parse(dateString)
    }

    return new Date
  }

  get elapsedTime() {
    const timeNow = new Date
    return timeNow - this.startTime
  }

  get secondsElapsed() {
    return Math.floor(this.elapsedTime / 1000)
  }

  get displayHours() {
    return Math.floor(this.secondsElapsed / 60 / 60)
  }

  get displayMinutes() {
    const minutesElapsed = Math.floor(this.secondsElapsed / 60 % 60)
    return this.zeroPad(minutesElapsed, 2)
  }

  get displaySeconds() {
    const seconds = Math.floor(this.secondsElapsed % 60)
    return this.zeroPad(seconds, 2)
  }
}
