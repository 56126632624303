import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input']

  connect(event) {
    // this conditional bit isn't compatible with the Trix editor
    if (this.inputTarget.value) {
      const end = this.inputTarget.value.length
      this.inputTarget.setSelectionRange(end, end)
    }

    this.inputTarget.focus()
  }
}
