import { Controller } from "@hotwired/stimulus"

// Take a file from a drag and drop event, parse it as JSON, iterate over the fields
// and convert them to HTML form inputs.
export default class extends Controller {
  static targets = [ "dropzone", "input", "form", "errorMessage", "successMessage" ]
  static values = { requiredFields: Array, formName: String }

  connect() {
    this.createFormFields()
  }

  dragover(event) {
    event.preventDefault()
    this.hover(true)
  }

  dragleave(event) {
    event.preventDefault()
    this.hover(false)
  }

  drop(event) {
    event.preventDefault()
    const files = event.dataTransfer.files
    this.hover(false)
    if(files.length > 1) {
      this.setError("Only one Credential JSON is allowed.")
    }
    else {
      this.inputTarget.files = files
      this.addFile()
    }
  }

  addFile() {
    const file = this.inputTarget.files[0]
    if(file) {
      var reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = (event) => {
        try {
          const json = JSON.parse(event.target.result)
          this.setFormFields(json)
          this.setSuccessMessage(`Successfully loaded credentials JSON.`)
          this.hideErrorMessage()
        }
        catch(error) {
          this.setErrorMessage(`Error reading file as a Credential JSON.`)
        }
      }
      reader.onerror = (event) => {
        this.errorMessageTarget.innerText = "Error reading file"
      }
    }
  }

  setFormFields(json) {
    this.requiredFieldsValue.forEach(field => {
      if(!json[field]) {
        throw `Missing required field ${field}`
      }

      this.updateFormField(field, json[field])
    })


  }

  createFormFields() {
    this.requiredFieldsValue.forEach(field => {
      const input = document.createElement("input")
      input.type = "text"
      input.name = `${this.formNameValue}[${field}]`
      input.classList.add("hidden")
      // Mark the field as required for the required input controller
      input.dataset.requiredInputTarget = 'requiredInput'
      this.formTarget.appendChild(input)
    })
  }

  updateFormField(field, value) {
    const fieldName = `${this.formNameValue}[${field}]`
    const input = document.querySelector(`input[name="${fieldName}"]`)

    if(input) {
      input.value = value
    }
  }

  setSuccessMessage(message) {
    this.successMessageTarget.innerText = message
    this.dispatch('update')
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    this.errorMessageTarget.classList.remove("hidden")
    this.dispatch('update')
  }

  hideErrorMessage() {
    this.errorMessageTarget.classList.add("hidden")
  }

  chooseFile() {
    this.inputTarget.click()
  }

  hover(force) {
    this.dropzoneTarget.classList.toggle("drop-zone__hover", force)
  }
}
