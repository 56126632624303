import { Controller } from "@hotwired/stimulus";
import { debounce } from "../debounce"

export default class extends Controller {
  static targets = [ 'form' ]

  initialize() {
    this.submit = debounce(this.submit, 1000).bind(this)
  }

  submit(event) {
    this.formTarget.submit()
  }
}
