import { DirectUpload } from "@rails/activestorage"

export default class Uploader {
  constructor(file, url) {
    this.upload = new DirectUpload(file, url, this)
  }

  start(callback) {
    this.upload.create((error, blob) => {
      if (error) {
        callback(error, null)
      } else {
        callback(null, blob)
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const progress = event.loaded / event.total * 100
    const progressElement = document.getElementById(`direct-upload-progress-${this.uploadId}`)
    progressElement.style.width = `${progress}%`
  }

  get uploadId() {
    return this.upload.id
  }
}
