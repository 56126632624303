import c0 from './autofocus_controller';
import c1 from './autologin_controller';
import c2 from './cable_controller';
import c3 from './carousel_controller';
import c4 from './cart_controller';
import c5 from './clipboard_controller';
import c6 from './disable_controller';
import c7 from './disable_trix_attachments_controller';
import c8 from './dismissable_alert_controller';
import c9 from './form_submit_controller';
import c10 from './json_parse_controller';
import c11 from './loading_indicator_controller';
import c12 from './modal_controller';
import c13 from './modal_creator_controller';
import c14 from './online_controller';
import c15 from './options_toggle_controller';
import c16 from './password_toggler_controller';
import c17 from './required_input_controller';
import c18 from './resizer_controller';
import c19 from './searchable_dropdown_controller';
import c20 from './stripe_controller';
import c21 from './tab_filters_controller';
import c22 from './timer_controller';
import c23 from './toggle_controller';
import c24 from './upload_controller';
import c25 from './video_chat_controller';
import c26 from './video_preview_controller';
export const definitions = [
	{identifier: 'autofocus', controllerConstructor: c0},
	{identifier: 'autologin', controllerConstructor: c1},
	{identifier: 'cable', controllerConstructor: c2},
	{identifier: 'carousel', controllerConstructor: c3},
	{identifier: 'cart', controllerConstructor: c4},
	{identifier: 'clipboard', controllerConstructor: c5},
	{identifier: 'disable', controllerConstructor: c6},
	{identifier: 'disable-trix-attachments', controllerConstructor: c7},
	{identifier: 'dismissable-alert', controllerConstructor: c8},
	{identifier: 'form-submit', controllerConstructor: c9},
	{identifier: 'json-parse', controllerConstructor: c10},
	{identifier: 'loading-indicator', controllerConstructor: c11},
	{identifier: 'modal', controllerConstructor: c12},
	{identifier: 'modal-creator', controllerConstructor: c13},
	{identifier: 'online', controllerConstructor: c14},
	{identifier: 'options-toggle', controllerConstructor: c15},
	{identifier: 'password-toggler', controllerConstructor: c16},
	{identifier: 'required-input', controllerConstructor: c17},
	{identifier: 'resizer', controllerConstructor: c18},
	{identifier: 'searchable-dropdown', controllerConstructor: c19},
	{identifier: 'stripe', controllerConstructor: c20},
	{identifier: 'tab-filters', controllerConstructor: c21},
	{identifier: 'timer', controllerConstructor: c22},
	{identifier: 'toggle', controllerConstructor: c23},
	{identifier: 'upload', controllerConstructor: c24},
	{identifier: 'video-chat', controllerConstructor: c25},
	{identifier: 'video-preview', controllerConstructor: c26},
];
