import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["planCard", "selectedPlan", "planCost", "planInput", "submit"]
  static classes = ["active"]

  connect(event) {
    this.planCardTargets.filter(c => c.dataset.planSubscribed).forEach(c => this.highlightCard(c))
  }

  add(event) {
    this.selectedPlanTarget.innerHTML = this.planName
    this.planCostTarget.innerHTML = this.planCost
    this.planInputTarget.value = this.planId
    this.submitTarget.disabled = false
    this.highlightCard(event.target)
  }

  // private

  highlightCard(card) {
    this.planCardTargets.forEach(c => c.classList.remove(this.activeClass))
    card.classList.add(this.activeClass)
  }

  get planName() {
    return event.currentTarget.dataset.planName
  }

  get planCost() {
    return event.currentTarget.dataset.planCost
  }

  get planId() {
    return event.currentTarget.dataset.planId
  }
}
