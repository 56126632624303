import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['slide', 'items', 'prev', 'next', 'indexLink', 'navigation']

  initialize() {
    this.index = 0
    this.createNavigationButtons()
    this.updateCarousel()
  }

  next() {
    this.index += 1
    this.updateCarousel()
  }

  previous() {
    this.index -= 1
    this.updateCarousel()
  }

  setIndex(e) {
    this.index = e.currentTarget.dataset.slideIndex
    this.updateCarousel()
  }

  // private

  createNavigationButtons() {
    if(this.needsNavigationItems) {
      const template = document.querySelector('#dotLink')

      this.slideTargets.forEach((slide, index) => {
        let button = template.content.firstElementChild.cloneNode(true)
        button.setAttribute('data-slide-index', index);

        this.navigationTarget.appendChild(button);
      });
    }
  }

  updateCarousel() {
    this.proposedPosition = this.index * this.cardWidth
    this.itemsTarget.scrollLeft = this.newPosition

    this.updateButtons()
    this.addActiveCssClasses()
  }

  updateButtons() {
    this.nextTargets.forEach(target => target.disabled = this.isEndOfCarousel)
    this.prevTargets.forEach(target => target.disabled = this.isStartOfCarousel)
  }

  addActiveCssClasses() {
    if(this.hasNavigationTarget) {
      this.indexLinkTargets.forEach((el, i) => {
        el.classList.toggle('active', this.index == i)
      })
    }

    this.slideTargets.forEach((el, i) => {
      el.classList.toggle('active', this.index == i)
    })
  }

  get cardWidth() {
    const target = this.slideTargets[0]
    const style = target.currentStyle || window.getComputedStyle(target)
    const boxWidth = target.offsetWidth
    const marginLeft = parseInt(style.marginLeft) || 0
    const marginRight = parseInt(style.marginRight) || 0

    return boxWidth + marginLeft + marginRight
  }

  get carouselWidth() {
    return this.itemsTarget.offsetWidth
  }

  get needsNavigationItems() {
    if(this.hasNavigationTarget && (this.navigationTarget.childElementCount < this.slideTargets.length)) {
        return true;
    }

    return false;
  }

  get isStartOfCarousel() {
    return this.proposedPosition <= 0
  }

  get isEndOfCarousel() {
    const cantScrollFurther = this.proposedPosition > this.maxPosition
    const allItemsShowing = this.carouselWidth == this.itemsWidth

    return allItemsShowing || cantScrollFurther
  }

  get itemsWidth() {
    return this.itemsTarget.scrollWidth
  }

  get maxPosition() {
    return this.itemsWidth - this.carouselWidth
  }

  get newPosition() {
    if (this.isEndOfCarousel) {
      return this.maxPosition
    }

    if (this.isStartOfCarousel) {
      this.index = 0
      return 0
    }

    return this.proposedPosition
  }
}
