import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "plan", "form", "instructions", "card", "errors", "token" ]
  static values = { publishableKey: String, requiresStripe: Boolean }
  static classes = [ "hidden" ]

  connect() {
    this.stripe = Stripe(this.publishableKeyValue)
    const elements = this.stripe.elements()

    this.card = elements.create("card", { style: {
      base: {
        fontSize: "24px" // 1.5rem
      },
      invalid: {
        iconColor: '#AE0452', // red-600
        color: '#AE0452', // red-600
      }
    }})
    this.card.mount(this.cardTarget)
    this.card.on("change", event => this.updateErrorMessage(event))
  }

  selectPlan(event) {
    this.requiresStripeValue = event.currentTarget.dataset.requiresStripe
  }

  requiresStripeValueChanged() {
    this.cardTarget.classList.toggle(this.hiddenClass, !this.requiresStripeValue)
    this.instructionsTarget.classList.toggle(this.hiddenClass, !this.requiresStripeValue)
  }

  updateErrorMessage(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
    } else {
      this.errorsTarget.textContent = ''
    }
  }

  submit(event) {
    event.stopImmediatePropagation()
    event.preventDefault()

    if (!this.requiresStripeValue) {
      this.formTarget.submit()
      return
    }

    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        this.updateErrorMessage(result)
      } else {
        this.tokenHandler(result.token)
      }
    })
  }

  tokenHandler(token) {
    this.tokenTarget.setAttribute("value", token.id)
    this.formTarget.submit()
  }
}
