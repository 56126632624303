import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['passwordInput']

  togglePassword(e) {
    e.preventDefault()
    this.passwordInputTarget.type = this.newInputType
  }

  // private

  get currentInputType() {
    return this.passwordInputTarget.type
  }

  get newInputType() {
    if (this.currentInputType == 'password') {
      return 'text'
    }
    return 'password'
  }
}
