import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'clickable', 'showable' ]

  connect() {
    this.toggle()
  }

  toggle() {
    const showClass = this.cssClassToShow

    this.showableTargets.forEach(t => {
      if (t.classList.contains(showClass)) {
        t.classList.remove('hidden')
      } else {
        t.classList.add('hidden')
      }
    })
  }

  // private

  get cssClassToShow() {
    let cssClass = ""
    this.clickableTargets.forEach(element => {
      if (element.checked) {
        cssClass = element.dataset.classToShow
      }
    });

    return cssClass
  }
}
