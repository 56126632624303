import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = {
    channel: String
  }
  connect() {
    this.subscription = createConsumer().subscriptions.create(
      {
        channel: this.channelValue
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {}

  _disconnected() {}

  _received(data) {}
}
