import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.isLoading) this.show()
  }

  disconnect() {
    this.hide()
  }

  show() {
    this.toggle(true)
  }

  hide() {
    this.toggle(false)
  }

  toggle(force) {
    this.element.classList.toggle("loadable__loading", force)
  }

  get isLoading() {
    return !this.isLoaded
  }

  get isLoaded() {
    return this.element.complete
  }
}
