import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    percentage: Number
  }

  initialize() {
    const givenPercentage = this.percentageValue
    this.percentage = givenPercentage / 100

    this.resizeChildren()
  }

  resizeChildren() {
    this.itemsToResize.forEach((item, index) => {
      item.style.minWidth = `${this.itemWidth}px`;
    });
  }

  handleWindowResize() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.resizeChildren()
    }, 750)
  }

  // private

  get itemsToResize() {
     return [...this.element.firstChild.children]
  }

  get itemWidth() {
    return this.percentage * this.wrapperWidth
  }

  get wrapperWidth() {
    return this.element.clientWidth
  }
}
